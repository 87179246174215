exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-onas-tsx": () => import("./../../../src/pages/onas.tsx" /* webpackChunkName: "component---src-pages-onas-tsx" */),
  "component---src-pages-sluzby-inuko-service-p-tsx": () => import("./../../../src/pages/sluzby/{InukoService.p}.tsx" /* webpackChunkName: "component---src-pages-sluzby-inuko-service-p-tsx" */),
  "component---src-pages-sluzby-tsx": () => import("./../../../src/pages/sluzby.tsx" /* webpackChunkName: "component---src-pages-sluzby-tsx" */)
}

